var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "purchacesOrderModalRef",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        id: "modal-add-po-item",
        title: _vm.rendTitleModal,
        "ok-title": _vm.FormMSG(201, "Ok"),
        "cancel-title": _vm.FormMSG(202, "Cancel"),
        "cancel-variant": "light",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        ok: _vm.handleSubmitItemPOModal,
        cancel: _vm.handleCancelItemPOModal,
        close: _vm.handleCancelItemPOModal,
        show: _vm.handleShowPOModal,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "card card-border-blue-light" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(2, "Item's description"),
                "label-for": "poitem_description",
              },
            },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "poitem_description",
                  rows: "3",
                  state: !_vm.$v.poItem.name.$error,
                },
                on: { input: _vm.handleInputName },
                model: {
                  value: _vm.$v.poItem.name.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.poItem.name, "$model", $$v)
                  },
                  expression: "$v.poItem.name.$model",
                },
              }),
              _vm.$v.poItem.name.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(13, "Name is required")) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("department-selector", {
            attrs: {
              "hide-department": "",
              "is-new": false,
              "is-submitted": _vm.isSubmitted,
              department: _vm.department,
              category: _vm.poItem.category,
              "edit-data": _vm.poItem,
              "hide-transportation-mean": "",
            },
            on: {
              change: _vm.handleDepartmentSelection,
              "department:selector:invalid": _vm.isInvalidateFields,
            },
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: `${_vm.FormMSG(327, "Unit price(vat excl.)")}`,
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              step: "0.01",
                              min: "0",
                              placeholder: "0",
                              state: !_vm.$v.poItem.price.$error,
                            },
                            on: { change: _vm.handleChangePrice },
                            model: {
                              value: _vm.$v.poItem.price.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.poItem.price, "$model", $$v)
                              },
                              expression: "$v.poItem.price.$model",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                [
                                  _c("currency-svg", {
                                    attrs: {
                                      color: "#06263E",
                                      opacity: "0.85",
                                      width: "15",
                                      height: "15",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.$v.poItem.price.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        14,
                                        "Please, value must be greater than 0"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: `${_vm.FormMSG(328, "Unit VAT")}` } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              step: "0.01",
                              min: "0",
                              placeholder: "0",
                            },
                            on: { change: _vm.handleChangePrice },
                            model: {
                              value: _vm.poItem.priceVat,
                              callback: function ($$v) {
                                _vm.$set(_vm.poItem, "priceVat", $$v)
                              },
                              expression: "poItem.priceVat",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                [
                                  _c("currency-svg", {
                                    attrs: {
                                      color: "#06263E",
                                      opacity: "0.85",
                                      width: "15",
                                      height: "15",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: `${_vm.FormMSG(329, "Unit price (vat incl.)")}`,
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              step: "1",
                              min: "0",
                              placeholder: "0",
                              disabled: "",
                            },
                            on: { change: _vm.handleChangePrice },
                            model: {
                              value: _vm.$v.poItem.unitPriceTotal.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.poItem.unitPriceTotal,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.poItem.unitPriceTotal.$model",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                [
                                  _c("currency-svg", {
                                    attrs: {
                                      color: "#06263E",
                                      opacity: "0.85",
                                      width: "15",
                                      height: "15",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: `${_vm.FormMSG(330, "Quantity")}` } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              step: "1",
                              min: "0",
                              placeholder: "0",
                              state: !_vm.$v.poItem.quantity.$error,
                            },
                            model: {
                              value: _vm.$v.poItem.quantity.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.poItem.quantity, "$model", $$v)
                              },
                              expression: "$v.poItem.quantity.$model",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                [
                                  _c("currency-svg", {
                                    attrs: {
                                      color: "#06263E",
                                      opacity: "0.85",
                                      width: "15",
                                      height: "15",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.$v.poItem.quantity.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        14,
                                        "Please, value must be greater than 0"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }