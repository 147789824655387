<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		no-close-on-backdrop
		no-close-on-esc
		id="modal-add-po-item"
		v-model="isOpen"
		ref="purchacesOrderModalRef"
		:title="rendTitleModal"
		:ok-title="FormMSG(201, 'Ok')"
		:cancel-title="FormMSG(202, 'Cancel')"
		@ok="handleSubmitItemPOModal"
		@cancel="handleCancelItemPOModal"
		@close="handleCancelItemPOModal"
		@show="handleShowPOModal"
		cancel-variant="light"
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="card card-border-blue-light">
			<b-form-group :label="FormMSG(2, 'Item\'s description')" label-for="poitem_description">
				<b-form-textarea v-model="$v.poItem.name.$model" id="poitem_description" rows="3" :state="!$v.poItem.name.$error" @input="handleInputName" />
				<div v-if="$v.poItem.name.$error" class="invalid-feedback">
					{{ FormMSG(13, 'Name is required') }}
				</div>
			</b-form-group>
			<department-selector
				hide-department
				:is-new="false"
				:is-submitted="isSubmitted"
				:department="department"
				:category="poItem.category"
				:edit-data="poItem"
				hide-transportation-mean
				@change="handleDepartmentSelection"
				@department:selector:invalid="isInvalidateFields"
			/>
			<b-row>
				<b-col cols="6">
					<b-form-group :label="`${FormMSG(327, 'Unit price(vat excl.)')}`">
						<b-input-group>
							<b-form-input
								v-model="$v.poItem.price.$model"
								type="number"
								step="0.01"
								min="0"
								placeholder="0"
								:state="!$v.poItem.price.$error"
								@change="handleChangePrice"
							/>
							<b-input-group-append>
								<b-input-group-text>
									<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
								</b-input-group-text>
							</b-input-group-append>
							<div v-if="$v.poItem.price.$error" class="invalid-feedback">
								{{ FormMSG(14, 'Please, value must be greater than 0') }}
							</div>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col cols="6">
					<b-form-group :label="`${FormMSG(328, 'Unit VAT')}`">
						<b-input-group>
							<b-form-input v-model="poItem.priceVat" type="number" step="0.01" min="0" placeholder="0" @change="handleChangePrice" />
							<b-input-group-append>
								<b-input-group-text>
									<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="6">
					<b-form-group :label="`${FormMSG(329, 'Unit price (vat incl.)')}`">
						<b-input-group>
							<b-form-input
								v-model="$v.poItem.unitPriceTotal.$model"
								type="number"
								step="1"
								min="0"
								placeholder="0"
								disabled
								@change="handleChangePrice"
							/>
							<b-input-group-append>
								<b-input-group-text>
									<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col cols="6">
					<b-form-group :label="`${FormMSG(330, 'Quantity')}`">
						<b-input-group>
							<b-form-input
								v-model="$v.poItem.quantity.$model"
								type="number"
								step="1"
								min="0"
								placeholder="0"
								:state="!$v.poItem.quantity.$error"
							/>
							<b-input-group-append>
								<b-input-group-text>
									<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
								</b-input-group-text>
							</b-input-group-append>
							<div v-if="$v.poItem.quantity.$error" class="invalid-feedback">
								{{ FormMSG(14, 'Please, value must be greater than 0') }}
							</div>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isNil, omit, greaterThanZero } from '@/shared/utils';
import { addPoItem, updatePoItem, computePoItemPrice } from '@/cruds/po-item.crud';
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import { required, decimal, minLength } from 'vuelidate/lib/validators';
import _ from 'lodash';
import CurrencySvg from '@/components/Svg/Currency';

const defaultPOsItem = {
	name: '',
	description: '',
	quantity: 0,
	price: 0,
	category: null,
	priceVat: 0,
	unitPriceTotal: 0
};

export default {
	name: 'ItemPoServiceComponentModal',
	mixins: [languageMessages, validationMixin],
	components: {
		DepartmentSelector: () => ({
			component: import('@/components/DepartmentsSelection')
		}),
		CurrencySvg
	},
	props: {
		value: {
			type: Boolean,
			default: false
		},
		department: {
			type: Number,
			required: true
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		currentPoId: {
			type: [Number, String, null],
			required: true,
			default: null
		}
	},
	data() {
		return {
			poItem: Object.assign({}, defaultPOsItem),
			isSubmitted: false,
			isInvalid: false,

			isInit: true
		};
	},
	computed: {
		...mapGetters({
			taxOptions: 'flag/taxOptions'
		}),
		isOpen: {
			get() {
				if (this.isInit === true) {
					if (this.value) this.initModal();
				}
				return this.value;
			},
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				this.$emit('input', status);
			}
		},
		rendTitleModal() {
			const _t = this.FormMSG;
			const e = !isNil(this.editData);
			const t = e ? _t(15, 'Edit') : _t(16, 'Add');
			return `${t}  ${_t(17, 'purchase order item')}`;
		},
		isEditMode() {
			return !isNil(this.editData);
		}
	},
	async created() {
		await this.getTaxOptions();
	},
	methods: {
		...mapActions({
			getTaxOptions: 'flag/getTaxOptions'
		}),
		handleShowPOModal() {
			this.isInit = false;
		},
		async handleChangePrice() {
			await this.computePoItemPrice();
		},
		async computePoItemPrice() {
			const input = {
				unitPriceVatExcluded: parseFloat(this.poItem.price),
				unitPriceVatIncluded: 0,
				unitVat: parseFloat(this.poItem.priceVat),
				quantity: parseFloat(this.poItem.quantity),
				category: parseInt(this.poItem.category),
				department: parseInt(this.department)
			};

			const result = await computePoItemPrice(input);

			this.poItem.price = result.unitPriceVatExcluded;
			this.poItem.unitPriceTotal = result.unitPriceVatIncluded;
			this.poItem.priceVat = result.unitVat;
			this.poItem.quantity = result.quantity;
			this.poItem.category = result.category;
			this.poItem.department = result.department;

			return result;
		},
		handleInputName(payload) {
			this.poItem.description = payload;
		},
		initModal() {
			this.poItem = this.isEditMode ? this.editData : Object.assign({}, defaultPOsItem);

			// @TODO: must be multiselect
			// if (this.isEditMode && !_.isNil(this.editData.projectFlagPoItem) && this.editData.projectFlagPoItem.length > 0) {
			// 	this.poItem.typeVat = this.editData.projectFlagPoItem[0];
			// }

			if (this.isEditMode) {
				this.poItem.unitPriceTotal = this.editData.price + this.editData.priceVat;
			}
		},
		async handleSubmitItemPOModal(e) {
			e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();
			if (this.$v.$invalid || this.isInvalid) {
				return;
			}

			if (isNil(this.currentPoId)) return;

			const payload = {
				poid: this.currentPoId,
				item: omit(['id', '__typename', 'poTotal'], {
					...this.poItem,
					quantity: parseInt(this.poItem.quantity),
					price: parseFloat(this.poItem.price),
					priceVat: parseFloat(this.poItem.priceVat),
					category: parseInt(this.poItem.category),
					priceTotal: parseFloat(this.poItem.price) * parseFloat(this.poItem.quantity),
					priceVatTotal: parseFloat(this.poItem.priceVat) * parseFloat(this.poItem.quantity)
				})
			};

			let emittablePayload = null;

			if (!this.isEditMode) {
				const { price, quantity, total, priceVat, priceTotal, priceVatTotal } = await addPoItem(payload);
				emittablePayload = {
					price,
					quantity,
					total,
					priceVat,
					priceTotal,
					priceVatTotal
				};
			} else {
				const { price, quantity, total, priceVat, priceTotal, priceVatTotal } = await updatePoItem({
					id: parseInt(this.poItem.id),
					...payload
				});
				emittablePayload = {
					price,
					quantity,
					total,
					priceVat,
					priceTotal,
					priceVatTotal
				};
			}

			this.$emit('po-item:submitted', emittablePayload);

			this.poItem = Object.assign({}, defaultPOsItem);
			this.isOpen = false;
			this.isSubmitted = false;
		},
		handleCancelItemPOModal() {
			this.poItem = Object.assign({}, defaultPOsItem);
			this.isOpen = false;
			this.isSubmitted = false;
			this.isInvalid = false;
			this.isInit = true;
		},
		handleDepartmentSelection({ category }) {
			this.poItem.category = category;
		},
		isInvalidateFields(payload) {
			this.isInvalid = payload;
		},
		selectableItem(item) {
			return !item.disabled;
		}
	},
	validations: {
		poItem: {
			name: {
				required,
				min: minLength(4)
			},
			quantity: {
				required,
				decimal,
				min: greaterThanZero
			},
			price: {
				required,
				decimal,
				min: greaterThanZero
			},
			unitPriceTotal: {
				required,
				decimal,
				min: greaterThanZero
			}
		}
	}
};
</script>
<style lang="scss">
#modal-add-po-item label,
#modal-add-po-item legend {
	font-size: 14px !important;
	margin-bottom: 0.5rem !important;
}
</style>
